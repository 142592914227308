.eventContainer {
  display: flex;
  z-index: -1;
  flex-direction: column;
  white-space: normal;
  padding: 8px 10px;
  color: white;
  background-color: #647984;
  position: absolute;
  border: 1px solid #919da1;
  width: 100%;
  border-radius: 5px;
  font-size: 13px;
  overflow-wrap: break-word;
  z-index: 2;
  cursor: pointer;
  -webkit-appearance: none;
  overflow-y: scroll;
}

.eventButtonHover {
  display: flex;
  z-index: -1;
  flex-direction: column;
  align-items: flex-start;
  white-space: normal;

  padding: 0 1vw;
  color: #ffffff;
  background: #a0c3d1;
  position: absolute;
  border: 1px solid #919da1;
  width: 100%;
  border-radius: 5px;

  z-index: 2;
  cursor: pointer;
  -webkit-appearance: none;
  overflow-y: scroll;
}

@media only screen and (max-width: 813.99px) {
  .eventContainer {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1150px) {
  .eventContainer {
    font-size: 13px;
  }
}

@media only screen and (max-width: 669.99px) {
  .eventContainer {
    align-items: center;
    justify-content: center;
  }
}
