.bodyContainer {
  /* background: linear-gradient(-45deg, #f1edfa, #f1edfa, #ccbeee, #f1edfa); */
  /* background-size: 400% 400%; */
  animation: gradient 15s ease infinite;
  margin-top: 100px;
}

.titleRow {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.horizontalContainer {
  padding-left: 2vw;
  padding-right: 2vw;
  /* padding-top: 20vh; */
}

.titleContainer {
  display: flex;
  flex-direction: row;
  width: 20%;
}

.contactTitle {
  /* margin-left: 0; */
  width: 100%;
  /* text-align: center; */
  font-size: 35px;
  color: #4b4b4b;
}

.imgContainer {
  /* width: 30%; */
  height: 50px;
}

Accordion {
  font-size: 100px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  min-width: 50vw;
  align-items: center;
  justify-content: space-evenly;
}

.button {
  background-color: #fc4e4e;
  color: #ffffff;
  text-transform: unset !important;
  /* border: 1px #5a32bf; */
  border-radius: 50px;
  box-shadow: 5px 5px #a0c3d1;
  min-height: 50px;
  padding: 0 2vw;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 10px;
  width: 100%;
}
.button:hover {
  color: #fc4e4e;
}
