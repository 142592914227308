.barContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border: 1px solid #5a32bf;
  border-radius: 5px;
  width: 95%;
  margin: 5px 1vw;
  box-sizing: border-box;
  text-align: center;
  font-weight: lighter;
  font-size: small;
  background-color: #7048d5;
  color: #ffffff;
  cursor: pointer;
}

.actionButton {
  color: #ffffff;
  background-color: #5a32bf;
  border-radius: 5px;
}
