.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  /* background: linear-gradient(-45deg, #f1edfa, #f1edfa, #ccbeee, #f1edfa ); */
  background-color: #ffffff;
  /* background-color: #7048d5; */

  /* background-size: 400% 400%; */
  animation: gradient 15s ease infinite;
}
.mainVerticalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  height: 90%;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
  /* padding-top: 20vh; */

  /* margin-bottom: 30px; */
}
.verticalContainer {
  display: flex;
  /* flex: 1 30%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 90%;

  padding-left: 1vw;
  padding-right: 1vw;
  /* padding-top: 20vh; */

  /* margin-bottom: 30px; */
}

.horizontalContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;

  bottom: 0;
  height: 50%;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
  /* padding-top: 20vh; */
}

.footerContent {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  color: #000000;
  font-size: 18px;
  max-width: 50ch;
  padding-left: 1vw;
  font-family: neuzeit-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.loginImage {
  /* width: 40vw; */
  height: 500px;
  /* max-height: 50vh;
  flex: 0 0 46.666667%; */

  /* float: right; */
}
.logoImage {
  height: 100px;
}
.schedulerImage {
  height: 40vh;
  float: left;
}

.loginGraphicContainer {
  flex-grow: 1;
  /* padding-right: 2vw; */
}

.bodyContainer {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

h1 {
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-image: linear-gradient(-45deg, #fec2c2, #5a32bf);
  background-image: conic-gradient(-45deg, #fec2c2, #5a32bf);

  font-size: 92px !important;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.012em;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

h2 {
  color: #000000;

  font-size: 32px !important;
  line-height: 1.125;
  font-weight: 500;
  letter-spacing: 0.004em;
  font-family: neuzeit-grotesk, sans-serif;
  margin: 20px auto 0;
}

h3 {
  color: #000000;

  font-size: 52px !important;
  line-height: 1.125;
  font-weight: 500;
  letter-spacing: 0.004em;
  font-family: neuzeit-grotesk, sans-serif;
  margin: 20px auto 0;
}

.content {
  color: #000000;
  font-size: 18px;
  max-width: 50ch;
  padding-left: 1vw;
  font-family: neuzeit-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.button {
  background-color: #fc4e4e;
  color: #ffffff;
  text-transform: unset !important;
  /* border: 1px #5a32bf; */
  border-radius: 50px;
  box-shadow: 5px 5px #a0c3d1;
  min-height: 50px;
  padding: 0 2vw;
  text-align: center;
  font-size: 15px;
  line-height: 22px;
  text-decoration: none;
  margin-top: 10px;
}

.button:hover {
  color: #ffffff;
  box-shadow: 5px 5px #a0c3d1;
  background-color: #5a32bf;
  padding: 0 2vw;
  /* border: #fc4e4e solid 1px; */
  text-decoration: none;
}

/* body {
  background: linear-gradient(-45deg, #ccbeee, #d9cef2, #fed7d7, #fec2c2);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
} */

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (max-width: 600px), screen and (max-height: 1200px) {
  .homeContainer {
  }
  .verticalContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    /* margin-bottom: 40px; */
  }
  .loginImage {
    height: 300px;
    /* margin-top: 100px; */
  }
  h2 {
    font-size: 28px !important;
  }
  .button {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 550px), screen and (max-height: 800px) {
  .verticalContainer {
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
    /* margin-bottom: 40px; */
  }
  .loginImage {
    height: 300px;
    /* margin-top: 50px; */
  }
  h2 {
    font-size: 28px !important;
  }
  .button {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 450px), screen and (max-height: 780px) {
  .verticalContainer {
    /* padding-top: 30px; */
    padding-bottom: 30px;
    height: 130%;
    /* margin-bottom: 40px; */
  }
  .loginImage {
    height: 300px;
    /* margin-top: 100px; */
  }

  .button {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 450px), screen and (max-height: 500px) {
  .verticalContainer {
    padding-top: 30px;
    padding-bottom: 50px;
    min-height: 120%;
  }

  .loginImage {
    height: 300px;
    /* margin-top: 300px; */
  }

  .button {
    margin-bottom: 50px;
  }
  .footerContainer {
    min-height: 90%;
  }

  h1 {
    font-size: 62px !important;
  }

  h2 {
    font-size: 20px !important;
  }
  h3 {
    font-size: 32px !important;
  }
}

Accordion {
  background-color: #5a32bf;
}
