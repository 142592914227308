.container {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.dayControlContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100%;
  justify-content: space-around;
  align-items: flex-start;
}

.shrinkCalendarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 100%;
  justify-content: space-around;
  align-items: flex-start;
  transform: translate(-6vw);
}

.timeSlotContainer {
  flex-grow: 1;
  margin: 0 1vw;
  transform: translateY(30px);
  color: #70757a;
}

.shrinkTimeSlotContainer {
  flex-grow: 1;
  margin: 0 1vw;
  transform: translateY(-10px);
  color: #70757a;
}

.timePrefTimeSlotContainer {
  flex-grow: 1;
  margin: 0 1vw;
  transform: translateY(75px);
  color: #70757a;
}

.shrinkTimePrefTimeSlotContainer {
  flex-grow: 1;
  margin: 0 1vw;
  transform: translateY(40px);
  color: #70757a;
}

.timeSlotTitle {
  height: 0px;
  /* position: fixed; */
}

.timeSlot {
  height: 80px;
  text-align: right;
  /* align-content: center; */
}

@media only screen and (max-width: 669.99px) {
  .timeSlotContainer {
    margin: 0 1vw 0 0;
  }
}
