@font-face {
  font-family: "Eina03";
  src: local("Eina03-SemiBold"),
    url("../../../fonts/Eina03-SemiBold.ttf") format("truetype");
}

.headerContainer {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* align-items: center; */
  background-color: #5a32bf;
  /* background-color: rgba(241, 237, 250, 0.9); */
  padding-left: 5vw;
  padding-right: 5vw;

  width: 100%;
  height: 80px;
  z-index: 1000000;
}

.leftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 10px;
    margin-right: 10px; */
  /* width: 100%; */
  height: 100%;
}

.splitContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.logoContainer {
  /* margin-right: 20px;     */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

/* .verticalBar {
  border-left: #000000 1px solid;
  height: 40px;
  text-decoration: none;
} */

.navbarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-left: 2vw;
}

.navbarContainerGuest {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navOption {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  color: white; 
  font-weight: bold;
  line-height: 15px;
}

.navOption:hover {
  color: white;
  padding-bottom: 4.7%;
  padding-top: 5.6%;
  height: 100%;
  border-top: white 5px solid;
}

/* .activeNavOption {
  padding-left: 10px;
  padding-right: 10px;

  padding-bottom: 4.7%;
  padding-top: 5.6%;

  color: #5a32bf;
  align-items: center;

  height: 100%;
  border-top: #5a32bf 5px solid;

  white-space: nowrap;
} */

.activeNavOption:hover {
  color: white;
  padding-bottom: 4.7%;
  padding-top: 5.6%;
  border-top: white 5px solid;
}

.button {
  color: #ffffff !important;
  /* color: #000000 !important; */
  text-transform: unset !important;
  border: none;
  box-sizing: border-box;

  font-weight: normal;
  line-height: 15px;

  margin-top: 10px;
}

.shrinkNavbar {
  font-size: 50px;
  color: #5a32bf;
  margin-right: 2vw;
}

.shrinkNavbar:hover {
  color: #fc4e4e;
  cursor: pointer;
  background-color: ;
}

.expandableContainer {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: #efebf9;
  /* border-bottom: 5px solid #fc4e4e; */
  border-bottom-left-radius: 10px;
  /* box-sizing: content-box; */
  overflow-y: visible;
  transform: translate(-5vw, 81px);
  color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(90, 50, 191, 0.2),
    0 6px 20px 0 rgba(90, 50, 191, 0.19);
}

.shrinkNavOption {
  color: #000000;
  font-size: 25px;
  font-weight: lighter;
  font-family: neuzeit-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 100%;
  text-align: center;
  background-color: linear-gradient(
      rgba(160, 195, 209, 0.5),
      rgba(160, 195, 209, 0.5)
    ),
    linear-gradient(white, white);
}

.shrinkNavOption:hover {
  color: #ffffff;
  background-color: #fc4e4e;
}

.activeShrinkNavOption {
  /* width: 100%; */
  color: #ffffff;
  font-weight: normal;
  /* margin-top: 5px;
    margin-bottom: 5px; */
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #7048d5;
  font-weight: lighter;
  font-family: neuzeit-grotesk, sans-serif;
  font-style: normal;
  font-weight: 300;

  width: 100%;
  text-align: center;
}

.activeShrinkNavOption:hover {
  /* width: 100%; */
  color: #ffffff;

  background-color: #7048d5;
}
