.loginContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  background-color: #ffffff;
  width: 40%;

  border: #ffffff 1px solid;
  border-radius: 12px;

  padding-top: 10px;
  padding-bottom: 20px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  height: 50px;
  padding: 10px 1vw;
  margin-bottom: 20px;
  border-bottom: #7048d5 1px solid;
}

.closeButton {
  cursor: pointer;
}

.headerBody {
  font-weight: bold;
}

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 10px 1vw;
}

.inputContainer {
  border: #7048d5 1px solid;
  border-radius: 5px;
  margin-bottom: 20px;
  /* margin-botopttom: 20px; */
}

.nameContainer {
  display: flex;
  flex-direction: row;
}

.inputName {
  /* width: 30%; */
  height: 50px;

  cursor: pointer;
  flex-grow: 1;
}

.inputAreaTop {
  width: 100%;
  border: none;
  height: 50px;

  cursor: pointer;
}

.inputAreaBottom {
  width: 100%;
  border: none;
  height: 50px;
  cursor: pointer;

  border-top: #7048d5 1px solid;
}

.inputAreaTop:hover {
  border: #7048d5 1px solid;
}

.inputAreaBottom:hover {
  border: #7048d5 1px solid;
}

/* .inputAreaTop:invalid {
    border-top: #FC4E4E 1px solid;
}

.inputAreaBottom:invalid {
    border-top: #FC4E4E 1px solid;
} */

.submitButton {
  width: 100%;
  background-color: #fc4e4e;
  color: #ffffff;
  height: 50px;
  border: none;
}
.submitButton:hover {
  background-color: #fc6d6d;
}

.linkButton {
  color: #c0bbcf;
  cursor: pointer;
}

.linkButton:hover {
  color: #7048d5;
}

@media only screen and (max-width: 744px) {
  .loginContainer {
    width: 100%;
  }
}
