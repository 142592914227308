.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* background: linear-gradient(-90deg, #ccbeee, #d9cef2, #fed7d7, #fec2c2); */
  margin-top: 80px;
  width: 100%;
  padding-top: 2vh;
}
