.contentContainer {
  display: flex;
  flex-direction: column;
  /* background: linear-gradient(-90deg, #ccbeee, #d9cef2, #fed7d7, #fec2c2); */
  padding-top: 2vh;
}

.noSchedulewrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  padding-left: 2vw;
  padding-right: 2vw;
  height: 80vh;

  margin-top: 80px;
  color: #5a32bf;
}

/* * * * * * * * * * * PROGRESS BAR * * * * * * * * * * */

.progressBarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 20px;

  padding: 1vh;
}

.progressBarTitle {
  white-space: nowrap;

  color: #5a32bf;
  font-weight: bold;
  font-size: 25px;
}
.progressBar {
  /* border: #5a32bf 2px solid; */
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(90, 50, 191, 0.2),
    0 6px 20px 0 rgba(90, 50, 191, 0.19);
  width: 75%;
  height: 30px;
  width: 90%;
  margin-left: 1vw;
  margin-right: 1vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.progressBarCompleted {
  background-color: #a0c3d1;
  color: #a0c3d1;
  height: 100%;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  transition: width 1s ease-in-out;
}
.progressBarCurrent {
  background-color: #fc6d6d;
  color: #f0e4aa;
  height: 100%;

  transition: width 1s ease-in-out;
}
.progressBarFuture {
  background-color: #ffffff;
  color: #fc4e4e;
  height: 100%;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  transition: width 1s ease-in-out;
}

/* * * * * * * * * * * HORIZONTAL WRAPPER * * * * * * * * * * */
.horizontalWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;

  padding-left: 2vw;
  padding-right: 2vw;
}

/* * * * * * * * * * * leftContainer * * * * * * * * * * */
.leftContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 25%;
  width: 25vw;
  align-items: center;

  padding-right: 1vw;

  margin-bottom: 20px;
}

.planSelectorContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  /* border: 1px #5a32bf solid; */

  width: 100%;
  padding: 5px 1vw;

  white-space: nowrap;
}

/* SEARCH CONTAINER for Courses */
.existListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border: 1px solid #5a32bf; */

  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

input {
  border-radius: 5px;
  border: #5a32bf solid 1px;
  min-width: 80%;

  font-size: 15px;
  padding-left: 5px;
}

.inputSearch {
  width: 90%;
}

.searchButton {
  border-radius: 17px;
  border: #5a32bf solid 1px;
  margin-top: 10px;
  box-sizing: border-box;

  height: 35px;

  padding-left: 2vw;
  padding-right: 2vw;

  text-align: center;
  font-size: 13px;
  color: #ffffff;

  background-color: #5a32bf;
}

.searchButton:hover {
  background-color: #fc4e4e;
  border: #fc4e4e solid 1px;
}

.searchListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: content-box;
  margin-top: 10px;
  margin-bottom: 10px;

  /* border-top: 1px solid #5a32bf; */
  width: 100%;
  max-height: 200px;

  overflow-y: scroll;
}

/* Degree Requirement Container  */

.degreeReqContainer {
  display: flex;
  flex-direction: column;
  border-radius: 12px;

  width: 100%;
}

.degreeReqTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding-left: 1vw;
  padding-right: 1vw;

  background-color: #7048d5;
  color: #ffffff;

  height: 35px;
}

.degreeReqNextButton {
  cursor: pointer;
}

.degreeReqDetailContainer {
  /* display: flex; */
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 300px;
  padding: 10px 1vw;
  overflow-y: scroll;
  background-color: rgba(239, 235, 249, 0.9);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* * * * * * * * * * * rightContainer * * * * * * * * * * */

.rightContainer {
  display: flex;
  flex-direction: column;
  flex: 3 0 75%;
  width: 75vw;
  /* border: #5a32bf solid 1px; */

  border-radius: 12px;
  background-color: #efebf9;
  margin-bottom: 20px;
}

.semesterPlanTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #7048d5;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
  padding: 10px 2vw;
}

.semesterPlanTitle {
  font-size: 20px;
  font-weight: bold;
  padding-left: 24px;
  color: #ffffff;
}

.editSemesterButtonContainer {
  display: flex;
  flex-direction: row;
  /* align-items: center;
    align-content: stretch; */
  /* justify-content: flex-end; */
}

.editSemesterButton {
  height: 25px;
  width: 25px;

  top: 0;
  bottom: 0;

  color: #ffffff;
}
.editPlanButton {
  height: 25px;
  width: 25px;

  top: 0;
  bottom: 0;

  color: #7048d5;
}

.planCardsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  /* background-color: #5a32bf; */
  height: 600px;

  overflow: scroll;
  padding: 0 1vw;
  margin: 0;
}

/* ------------------ AddSemester Functional Comoponent ------------- */

.addSemesterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #ffffff;
  width: 40%;

  border: #ffffff 1px solid;
  border-radius: 12px;

  padding-top: 10px;
  padding-bottom: 20px;
}

.submitButton {
  width: 100%;
  background-color: #fc4e4e;
  color: #ffffff;
  height: 50px;
  border: none;
}

@media only screen and (max-width: 1334px) {
  .leftContainer {
    width: 100vw;
  }
  .rightContainer {
    width: 100vw;
  }

  .planCardsContainer {
    justify-content: center;
  }
}
