.cardContainer {
  flex: 1 1 23%;

  /* border: 1px #5a32bf solid; */
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(112, 72, 213, 0.25),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* background: rgba(254, 215, 215, 0.25); */
  background: #ffffff;
  height: 250px;
  /* min-width: 300px;
  max-width: 33%; */

  overflow: auto;
  /* padding-left: 1vw; 
  padding-right: 1vw; */
  padding: 0.5vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;

  margin-top: 10px;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.courseContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  .cardContainer {
    flex: 0 0 95%;
  }
}
@media only screen and (max-width: 985px) {
  .cardContainer {
    max-width: none;
  }
}
