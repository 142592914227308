/* * * * Note * * * * 
    that the h3 and h5 size affects the width of left and right col */
h5 {
  color: #000000;
  padding-left: 3vw;
  padding-right: 3vw;
  text-align: center;
}

input {
  border-radius: 5px;
  border: #000000 solid 1px;
  min-width: 80%;

  font-size: 15px;
  padding-left: 5px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * encaps both DEGREE SEARCH Wrapper & DEGREE REQ Wrapper * * * */
.horizontalWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 80px;
  align-content: center;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Includes the Searching for existing degree plan */
.DegreeSearchWrapper {
  display: flex;
  flex-direction: column;
  /* flex: 1 0 0; */
  align-content: center;
  /* flex-grow: 1; */
  width: 48vw;
  justify-content: flex-start;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 20px;
}

/* Includes the currently saved degree req drop down */
.existListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* border: 1px solid #5a32bf; */
  /* border-radius: 12px;
  background-color: rgba(239, 235, 249, 0.9); */
  /* background-color: #efebf9; */
  /* box-shadow: 0 4px 8px 0 rgba(90, 50, 191, 0.2),
    0 6px 20px 0 rgba(90, 50, 191, 0.19);

  box-sizing: border-box; */
  margin-top: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.inputSearch {
  width: 90%;
}
.searchButton {
  z-index: 2;
  border-radius: 17px;
  border: #7048d5 solid 1px;
  box-sizing: border-box;

  margin-top: 15px;
  height: 35px;

  padding-left: 2vw;
  padding-right: 2vw;
  text-align: center;
  font-size: 13px;
  color: #7048d5;
  font-weight: 900;

  background-color: #efebf9;
}

.searchButton:hover {
  border: #7048d5 solid 1px;
  color: #ffffff;

  background-color: #7048d5;
}

.searchListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: content-box;
  margin-top: 10px;
  margin-bottom: 10px;
  /* background-color: rgba(239, 235, 249, 0.9);; */
  /* border-top: 1px solid #5a32bf; */
  width: 100%;
  max-height: 150px;

  overflow-y: scroll;
}

.publicReqButton {
  background-color: rgba(160, 195, 209, 0.1);
  color: #5a32bf;
  width: 80%;
  margin-bottom: 10px;
}

.publicReqButton:hover {
  background-color: #efebf9;
  color: #fc4e4e;
}

.myListWrapper {
  display: flex;
  flex-direction: column;
  /* background-color: rgba(239, 235, 249, 0.9); */
  /* background-color: #efebf9; */
  box-shadow: 0 4px 8px 0 rgba(90, 50, 191, 0.2),
    0 6px 20px 0 rgba(90, 50, 191, 0.19);
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
.dropdownListWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: center;
}
.editPlanButton {
  height: 25px;
  width: 25px;

  top: 0;
  bottom: 0;

  color: #7048d5;
}
.newMajorMinorButton {
  font-size: 10px;
  color: #ffffff;
  background-color: #fc6d6d;
  cursor: pointer;
  border: #fc6d6d solid 1px;
  border-radius: 12px;
  margin-top: 10px;
  text-transform: unset !important;
}

.newMajorMinorButton:hover {
  background-color: #fc4e4e;
  border: #fc4e4e solid 1px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* * * * * The RIGHT COL, Includes the info of Selected Degree Req * * * * */
.DegreeReqWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48vw;

  padding-left: 5vw;
  padding-right: 5vw;

  margin-top: 20px;

  border-left: #5a32bf 1px solid;
}
.noDegreeReqWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48vw;
  color: #5a32bf;
  padding-left: 5vw;
  padding-right: 5vw;

  margin-top: 20px;

  border-left: #5a32bf 1px solid;
}
/* Within the DegreeReqWrapper, includes the list */
.DegreeReqListWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(239, 235, 249, 0.9);

  box-shadow: 0 4px 8px 0 rgba(90, 50, 191, 0.2),
    0 6px 20px 0 rgba(90, 50, 191, 0.19);
  /* border: 1px solid #5a32bf; */
  border-radius: 12px;

  box-sizing: content-box;
  margin-top: 1vh;
  width: 100%;
  padding-bottom: 10px;
}

.DegreeReqListTitle {
  text-align: center;
  color: #ffffff;
  background-color: #7048d5;
  /* border: 1px solid #5a32bf; */

  border-top-left-radius: 11px;
  border-top-right-radius: 11px;

  font-size: 25px;

  padding-top: 1vh;
  padding-bottom: 1vh;

  height: 50px;
  width: 100%;
}

.degreeReqListExpandable {
  height: 350px;
  width: 100%;
  padding: 10px 1vw;
  overflow-y: scroll;
}

.editButton {
  border-radius: 12px;
  border: #fc4e4e solid 1px;
  box-sizing: border-box;
  text-transform: unset !important;
  margin-top: 15px;
  height: 35px;

  padding-left: 1vw;
  padding-right: 1vw;
  text-align: center;
  font-size: 13px;
  color: #fc4e4e;
  width: 80%;
  background-color: #efebf9;
}
.editButton:hover {
  background-color: #fc6d6d;
  border: #fc6d6d solid 1px;
  color: #ffffff;
}

.saveButton {
  border-radius: 12px;
  border: #fc6d6d solid 1px;
  box-sizing: border-box;
  text-transform: unset !important;
  margin-top: 15px;
  height: 35px;
  width: 100%;
  padding-left: 1vw;
  padding-right: 1vw;
  text-align: center;
  font-size: 13px;
  color: #ffffff;

  background-color: #fc6d6d;
}
.saveButton:hover {
  background-color: #fc4e4e;
  border: #fc4e4e solid 1px;
  color: #ffffff;
}
.nextPageButton {
  text-align: right;
  font-size: 40px;
  color: #000000;
  text-decoration: none;
}

.nextPageButton:hover {
  text-decoration: none;
  color: #fc4e4e;
}

body {
  background-color: white;
}

/* Helps to wrap the left and right when screen is too small */

@media only screen and (max-width: 700px) {
  .DegreeReqWrapper {
    width: 100vw;
    border-left: none;
  }
  .DegreeSearchWrapper {
    width: 100vw;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.example {
  -ms-overflow-style: none;
}
